<!--设置管理布局-->
<template>
  <div id="setLayout">
    <div class="main">
      <transition name="slide-fade" mode="out-in">
          <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
#setLayout {
  width: 100%;
  height: 100%;
}
.main {
  width: 100%;
  height: 100%;
  padding: 20px;
  overflow: hidden;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>